import { styled } from '@/src/stitches.config'
import Text from '../Text/Text'
import LinkButton from '../../marketing/buttons/LinkButton'
import NextLink from 'next/link'
import Image from 'next/legacy/image'
import { PARTNER_CHECKOUT_PAGE } from '@/src/modules/pg-plus/constants'
import { TPartnerPayload } from '@/src/modules/partners/types'

const CheckoutSection = styled('div', {
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flex: 1,
    jc: 'center',
    bc: 'white',
    p: '0.5rem',
    maxHeight: '265px',
    boxShadow: 'lightgray 8px 6px 6px 6px',
})

const Container = styled('div', {
    display: 'flex',
    flex: 1,
    fd: 'column',
    ai: 'center',
})

const OrderTotalContainer = styled('div', {
    display: 'flex',
    fd: 'row',
    jc: 'space-between',
    width: '100%',
    p: '0.5rem',
    m: '7px 0 10px',
})

const OrderTotalLabel = styled(Text, {
    color: '$darkest',
    fontSize: '$5',
    fontWeight: 'bold',
    '@sm': {
        fontSize: '$6',
    },
})

const OrderTotalValue = styled(Text, {
    color: '$primary',
    fontSize: '$5',
    fontWeight: 'bold',
    '@sm': {
        fontSize: '$6',
    },
})

const Separator = styled('hr', {
    m: 'auto 1rem',
    borderTop: '1px solid $mediumGray',
    borderBottom: 'none',
    width: '100%',
})

const CheckoutButtonContainer = styled('div', {
    m: '23px auto 17px',
    minWidth: '275px',
    '& a': {
        fontSize: '$4',
        '@sm': {
            fontSize: '$5',
        },
    },
})

const ContinueShoppingLink = styled('a', {
    color: '$primary',
    fontSize: '$4',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '@sm': {
        fontSize: '$5',
    },
})

const NortonBBBImageContainer = styled('div', {
    display: 'flex',
    fd: 'row',
    m: '20px auto 5px',
    columnGap: '1rem',
})

interface CartFlyoutCheckoutProps {
    closeFlyout: Function
    orderTotal: string
    continueShoppingUrl: string
    partnerData?: TPartnerPayload
}

const CartFlyoutCheckout = ({
    closeFlyout,
    orderTotal,
    continueShoppingUrl,
    partnerData,
}: CartFlyoutCheckoutProps) => {
    const checkoutUrl: string = partnerData
        ? PARTNER_CHECKOUT_PAGE
        : '/checkout'

    return (
        <CheckoutSection>
            <Container>
                <OrderTotalContainer>
                    <OrderTotalLabel>Order Total:</OrderTotalLabel>
                    <OrderTotalValue>{`$${orderTotal}`}</OrderTotalValue>
                </OrderTotalContainer>
                <Separator />
                <CheckoutButtonContainer
                    onClick={() => {
                        closeFlyout()
                    }}
                >
                    <LinkButton
                        variant={'primary'}
                        size={'lg'}
                        label={'Checkout'}
                        url={checkoutUrl}
                    />
                </CheckoutButtonContainer>
                <NextLink href={continueShoppingUrl} passHref legacyBehavior>
                    <ContinueShoppingLink
                        onClick={() => {
                            closeFlyout()
                        }}
                    >
                        Continue Shopping
                    </ContinueShoppingLink>
                </NextLink>
            </Container>
        </CheckoutSection>
    )
}

export default CartFlyoutCheckout
