import { styled } from '@/src/stitches.config'
import { ColorfulContainer } from '../../Layout/Banners/StyledComponents'
import { gradientAnimation } from '@/src/common/animations'

const Wrapper = styled('div', {
    display: 'flex',
    ai: 'center',
    gap: '10%',
    my: 20,
    '& p': {
        mb: 0,
        textTransform: 'uppercase',
        textWrap: 'nowrap',
    },
})

const BGCGreenBackground = styled('div', {
    background: `-webkit-linear-gradient(87deg, #5A9A45 18.47%, #90D4DA 80%)`,
    width: '100vw',
    backgroundSize: '200% 110%',
    animation: `${gradientAnimation} 8s ease infinite`,
    textAlign: 'center',
    color: 'white',
    py: 15,
    marginBottom: 0
})

const TextBold = styled('span', {
    fontWeight: 'bold'
})

interface ISeparatorProps {
    text?: string
    padding?: string | number
    marginTop?: string | number
}

const CorporatePhoneBanner = ({ padding = 0, marginTop = 0 }: ISeparatorProps)  => {
    return (
        <Wrapper id={'option-separator'} style={{ padding: padding, marginTop: marginTop, marginBottom: 5 }}>
            <BGCGreenBackground>
               Talk to a bulk gift cards sales agent: (800) 543-7927
            </BGCGreenBackground>
        </Wrapper>
    )
}

export default CorporatePhoneBanner