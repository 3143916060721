import NextLink from 'next/link'
import { styled, theme } from '@/src/stitches.config'
import Image from 'next/legacy/image'
import { Text } from '@/src/common/components/elements/Text/Text'
import StarRating from '@/src/common/components/marketing/reviews/StarRating'
import {
    SALES_PHONE_NUMBER_DATA_ID,
    SALES_PHONE_NUMBER_FORMATTED,
    SALES_PHONE_NUMBER_TEL,
    SUPPORT_PHONE_NUMBER_FORMATTED,
    SUPPORT_PHONE_NUMBER_TEL,
} from '@/src/common/components/marketing/text/PhoneNumbers/strings'
import { SocialShareIcons } from '@/src/common/components/marketing/buttons/SocialShareIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { FAQ_PAGE } from '@/src/modules/pg-plus/constants'

const FooterWrapper = styled('footer', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const FooterTop = styled('div', {
    backgroundColor: '$darkest',
    color: '$white',
    py: '2.55rem',
    px: '1rem',
    width: '100%',
})
const SectionList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '@md': {
        flexDirection: 'row',
        ml: '1rem',
        gap: '0',
    },
})
const SectionListItem = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    '@md': {
        justifyContent: 'normal',
        mr: '4rem',
        padding: '0 2rem',
        '&:last-child': {
            borderRight: 'none',
        },
    },
})
const SectionListItemContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
})
const LinkHeaderContent = styled('p', {
    color: '$white',
    fontSize: '$5',
    marginTop: '0',
    marginBottom: '0',
    borderBottom: '1px solid white',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.5em',
    '@md': {
        borderBottom: 'none',
        '& svg': {
            display: 'none',
        },
        lineHeight: 'inherit',
    },
    '& svg.down': {
        transform: 'rotate(-180deg)',
        transition: 'transform 0.3s ease-out',
    },

    '& svg': {
        transform: 'rotate(0deg)',
        transition: 'transform 0.3s ease-out',
    },
})
const SectionListItemInnerContent = styled('div', {
    flexDirection: 'column',
    gap: '.5rem',
    transition: 'height 0.3s ease',
    overflow: 'hidden',
    '@md': {
        display: 'flex !important',
        overflow: 'unset',
        '& .pusher': {
            display: 'none',
        },
    },
    variants: {
        isOpen: {
            false: {
                display: 'flex',
                '& .pusher': {
                    marginTop: ' -100%',
                    transition: 'margin-top .3s ease',
                },
            },
            true: {
                display: 'flex',
                marginBottom: '1rem',
                '& .pusher': {
                    marginTop: '0%',
                    transition: 'margin-top .3s ease',
                },
            },
        },
    },
})

const StyledLink = styled('a', {
    fontSize: '$2',
    '&:hover': {
        textDecoration: 'underline',
    },
})
const FooterBottom = styled('div', {
    backgroundColor: '$white',
    padding: '1rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@md': {
        flexDirection: 'row',
    },
})
const FooterBottomLinks = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
    fontSize: '$2',
    '@md': {
        gap: '.75rem',
        width: '59%',
    },
})
const FooterBottomLink = styled('a', {
    color: '$darkest',
    '&:hover': {
        textDecoration: 'underline',
    },
    '&:before': {
        content: '|',
        mr: '.5rem',
        '@md': {
            mr: '.75rem',
        },
    },
})
const FooterBottomLogos = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.75rem',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '82%',
    mt: '1.25rem',
    '@md': {
        mt: 'unset',
        gap: '1rem',
        width: '30%',
        justifyContent: 'flex-end',
    },
})
const RatingWrapper = styled('span', {
    display: 'flex',
    gap: '.35rem',
})
const TermsAndConditionsText = styled('p', {
    margin: '0 1rem 1rem',
    fontSize: '$2',
    color: theme.colors.dark,
    '@sm': {
        margin: '0 4rem 1rem',
    },
})
const PhoneNumberWithTextWrapper = styled('div', {
    fontSize: '$2',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
    '& a': {
        textDecoration: 'underline',
    },
})

const styles = {
    marginTop: {
        marginTop: '1.5rem',
    },
    grayText: {
        color: theme.colors.mediumGray,
        '&:hover': {
            textDecoration: 'none',
        },
    },
}

const ImageWrapper = styled('div', {})

interface IFooterProps {
    isCorporate: boolean
}

export const ConsumerAndCorporateFooter = ({ isCorporate }: IFooterProps) => {
    const [isOpen, setIsOpen] = useState<boolean[]>([
        false,
        true,
        false,
        false,
        false,
        false,
    ])

    const toggleDropdown = (index: number) => {
        let oldIsOpen = [...isOpen]
        oldIsOpen[index] = !oldIsOpen[index]
        setIsOpen(oldIsOpen)
    }

    return (
        <FooterWrapper>
            <FooterTop>
                <SectionList>
                    <SectionListItem>
                        <SectionListItemContent>
                            <LinkHeaderContent
                                onClick={() => toggleDropdown(0)}
                            >
                                BuyGiftCards.com
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className={isOpen[0] ? 'down' : ''}
                                />
                            </LinkHeaderContent>
                            <SectionListItemInnerContent isOpen={isOpen[0]}>
                                <div className={'pusher'}></div>
                                <Text size={2}>495 Mansfield Avenue</Text>
                                <Text size={2}>Pittsburgh, PA 15205</Text>
                                <PhoneNumberWithTextWrapper>
                                    Sales:{' '}
                                    <a
                                        data-test-id={'footerSalesPhoneNumber'}
                                        data-phone={SALES_PHONE_NUMBER_DATA_ID}
                                        href={SALES_PHONE_NUMBER_TEL}
                                        role={'button'}
                                    >
                                        {SALES_PHONE_NUMBER_FORMATTED}
                                    </a>
                                </PhoneNumberWithTextWrapper>
                            </SectionListItemInnerContent>
                        </SectionListItemContent>
                    </SectionListItem>
                    <SectionListItem>
                        <SectionListItemContent>
                            <LinkHeaderContent
                                onClick={() => toggleDropdown(2)}
                            >
                                Gifting
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className={isOpen[2] ? 'down' : ''}
                                />
                            </LinkHeaderContent>
                            <SectionListItemInnerContent isOpen={isOpen[2]}>
                                <div className={'pusher'}></div>
                                <NextLink
                                    href={'/corporate/visa'}
                                    passHref
                                    legacyBehavior
                                >
                                    <StyledLink>Visa Gift Cards</StyledLink>
                                </NextLink>
                                <NextLink
                                    href={'/brands'}
                                    passHref
                                    legacyBehavior
                                >
                                    <StyledLink>Brands</StyledLink>
                                </NextLink>
                                <NextLink
                                    href={'/corporate'}
                                    passHref
                                    legacyBehavior
                                >
                                    <StyledLink>Corporate</StyledLink>
                                </NextLink>
                                <StyledLink
                                    data-test-id={'footer-buygiftcards-com'}
                                    href={
                                        'https://rewards.buygiftcards.com/rewards'
                                    }
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    Redeem BuyGiftCards.com Gift Card
                                </StyledLink>
                            </SectionListItemInnerContent>
                        </SectionListItemContent>
                    </SectionListItem>
                    <SectionListItem>
                        <SectionListItemContent>
                            <LinkHeaderContent
                                onClick={() => toggleDropdown(3)}
                            >
                                Company
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className={isOpen[3] ? 'down' : ''}
                                />
                            </LinkHeaderContent>
                            <SectionListItemInnerContent isOpen={isOpen[3]}>
                                <div className={'pusher'}></div>
                                <StyledLink
                                    rel="noopener"
                                    target="_blank"
                                    href="https://groups.giveinkind.com/employers/"
                                >
                                    Support Your Team with Give InKind
                                </StyledLink>
                            </SectionListItemInnerContent>
                        </SectionListItemContent>
                    </SectionListItem>
                    <SectionListItem>
                        <SectionListItemContent>
                            <LinkHeaderContent
                                onClick={() => toggleDropdown(5)}
                            >
                                24/7 Support
                                <FontAwesomeIcon
                                    icon={faAngleUp}
                                    className={isOpen[5] ? 'down' : ''}
                                />
                            </LinkHeaderContent>
                            <SectionListItemInnerContent isOpen={isOpen[5]}>
                                <div className={'pusher'}></div>
                                <PhoneNumberWithTextWrapper>
                                    Call us:{' '}
                                    <a
                                        role={'button'}
                                        data-test-id={
                                            'footerSupportPhoneNumber'
                                        }
                                        href={SUPPORT_PHONE_NUMBER_TEL}
                                    >
                                        {SUPPORT_PHONE_NUMBER_FORMATTED}
                                    </a>
                                </PhoneNumberWithTextWrapper>
                                <NextLink
                                    href={'/order-status'}
                                    passHref
                                    legacyBehavior
                                >
                                    <StyledLink>Track your order</StyledLink>
                                </NextLink>
                                <NextLink
                                    href={FAQ_PAGE}
                                    passHref
                                    legacyBehavior
                                >
                                    <StyledLink>FAQs</StyledLink>
                                </NextLink>
                                <StyledLink
                                    rel="noopener"
                                    target="_blank"
                                    href="https://perfectgiftsupport.zendesk.com/hc/en-us"
                                >
                                    Help center
                                </StyledLink>
                            </SectionListItemInnerContent>
                        </SectionListItemContent>
                    </SectionListItem>
                </SectionList>
            </FooterTop>
            <FooterBottom>
                <FooterBottomLinks>
                    <div>
                        &copy;
                        {new Date().getFullYear()} - Part of{' '}
                        <StyledLink
                            rel="noopener"
                            target="_blank"
                            href="https://www.wolfe.com/"
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            The Wolfe Companies, LLC
                        </StyledLink>
                        . All rights reserved.
                    </div>
                    <NextLink href={'/privacy-policy'} passHref legacyBehavior>
                        <FooterBottomLink>Privacy Policy</FooterBottomLink>
                    </NextLink>
                    <NextLink href={'/terms-of-use'} passHref legacyBehavior>
                        <FooterBottomLink>Terms of Use</FooterBottomLink>
                    </NextLink>
                    <NextLink
                        href={'/terms-of-service'}
                        passHref
                        legacyBehavior
                    >
                        <FooterBottomLink>Terms of Service</FooterBottomLink>
                    </NextLink>
                    <NextLink href={'/accessibility'} passHref legacyBehavior>
                        <FooterBottomLink>
                            Accessibility Statement
                        </FooterBottomLink>
                    </NextLink>
                    <NextLink href={'/sitemap.html'} passHref legacyBehavior>
                        <FooterBottomLink>Sitemap</FooterBottomLink>
                    </NextLink>
                </FooterBottomLinks>
                <FooterBottomLogos>
                    <ImageWrapper>
                        <Image
                            src={'/images/pci_compliant_badge.png'}
                            alt={'PCI Compliant Badge'}
                            width={78}
                            height={32}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                    <ImageWrapper>
                        <Image
                            src={'/images/visa_logo.png'}
                            alt={'Visa logo'}
                            width={59}
                            height={19}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                    <ImageWrapper>
                        <Image
                            src={'/images/mc_logo.png'}
                            alt={'Mastercard logo'}
                            width={59}
                            height={19}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                    <ImageWrapper>
                        <Image
                            src={'/images/american-express_logo.png'}
                            alt={'PCI Compliant Badge'}
                            width={78}
                            height={32}
                            layout={'fixed'}
                        />
                    </ImageWrapper>
                </FooterBottomLogos>
                {/* @TODO Add Norton badge once available  */}
            </FooterBottom>
            <TermsAndConditionsText>
                BuyGiftCards.com gifting is powered by PerfectGift.com, operated
                by The Wolfe Companies, LLC. Products available are sold by
                BuyGiftCards.com and provided through PerfectGift.com, other
                than items on partner sites which are sold by the gift card
                marketplace site specified by the referral link. Your use of
                this site subjects you to PerfectGift.com&apos;s and
                BuyGiftCard.com&apos;s terms of use, terms and conditions,
                privacy policy and refund & return policy. All designated
                trademarks and brands are the property of their respective
                owners. No claims of affiliation are made about the stores on
                this site. The PerfectGift Visa&reg; Gift Card and the Virtual
                Visa Gift Card are issued by Sutton Bank&reg;, Member FDIC,
                pursuant to a license from Visa U.S.A. Inc. No cash access or
                recurring payments. Card can be used everywhere Visa debit cards
                are accepted. Visa Virtual Account can be redeemed at every
                Internet, mail order, and telephone merchant everywhere Visa
                debit cards are accepted. Terms, conditions and expiration
                apply. The PerfectGift Mastercard&reg; Reward Cards are issued
                by Sutton Bank&reg;, Member FDIC, pursuant to a license from
                Mastercard International Incorporated. No cash access or
                recurring payments. Card can be used everywhere Mastercard debit
                cards are accepted. Mastercard Virtual Account can be redeemed
                at every Internet, mail order, and telephone merchant everywhere
                Mastercard debit cards are accepted. Terms, conditions and
                expiration apply.
            </TermsAndConditionsText>
        </FooterWrapper>
    )
}
export default ConsumerAndCorporateFooter
