// context/PartnerThemeContext.tsx
import React, { createContext, ReactNode, useContext } from 'react'
import { createTheme, theme } from '@/src/stitches.config'

const PartnerThemeContext = createContext<ReturnType<
    typeof createTheme
    > | null>(null)

type PartnerThemeProviderProps = {
    children: ReactNode
    theme?: ReturnType<typeof createTheme>
}

export const PartnerThemeProvider = ({
    children,
    theme,
}: PartnerThemeProviderProps) => {
    return (
        <PartnerThemeContext.Provider value={theme ?? null}>
            {children}
        </PartnerThemeContext.Provider>
    )
}

export const usePartnerTheme = () => {
    const context = useContext(PartnerThemeContext)

    if (!context) {
        // Return default PGC theme
        return theme
    }

    return context
}